import React from 'react'
import { graphql } from 'gatsby'
import LayoutMain from '../components/layouts/LayoutMain'
import Image from 'gatsby-image'
import TechTags from '../components/TechTags'
import { FaBackward } from 'react-icons/fa'
import { Link } from 'gatsby'

// FaPhp
// FaL
// FaJs
// FaWordpress
// Knockout is being served from a custom generated font

const WorkTemplate = ({ data }) => {
	const { name, url, tech, description, mainImage, images } = data.work

	return (
		<LayoutMain>
			<Link to="/" className="page-nav nav-back">
				<FaBackward />
				<span className="page-nav-text">Back</span>
			</Link>
			<div className="work-title-bar">
				<div className="col-left">
					<h3 className="label-client-name">Client:</h3>
					<h2>{name}</h2>
				</div>
				<div className="col-right">
					<h3 className="label-client-name">Website:</h3>
					<a className="project-url" href={url} target="_blank" rel="noopener noreferrer">
						{url}
					</a>
				</div>
			</div>

			<div className="work-sample-container">
				<div className="description-container">
					<h3 className="label-description">About the {name} Project</h3>

					<div className="description">
						{description.content.map(item => {
							return item.content.map((nextItem, key) => {
								return <p key={key}>{nextItem.value}</p>
							})
						})}
					</div>
				</div>
				<div className="info-box">
					{/* <h2>Client: {name}</h2> */}
					{/* <h3>{subtitle}</h3> */}

					<TechTags tags={tech} />
				</div>
				<div className="project-images-container">
					<h3 className="label-images">Sample Screengrabs</h3>
					<div className="project-main-image-container">
						<Image fluid={mainImage.fluid} />
					</div>

					<div className="project-images">
						{images.map((item, index) => {
							return (
								<div key={index} className="img-border">
									<Image fluid={item.fluid} alt={name} className="project-image" />
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</LayoutMain>
	)
}

export default WorkTemplate

export const query = graphql`
	query($slug: String!) {
		work: contentfulWorkExamples(slug: { eq: $slug }) {
			name
			subtitle
			url
			id: contentful_id
			tech: technologies
			description {
				content {
					content {
						value
					}
				}
			}
			mainImage {
				fluid {
					...GatsbyContentfulFluid_withWebp
				}
			}
			images {
				fluid {
					...GatsbyContentfulFluid_withWebp
				}
			}
		}
	}
`
