import React from 'react'
import { FaPhp, FaLess, FaJs, FaWordpress, FaBootstrap, FaSass, FaGrin } from 'react-icons/fa'

// TODO: Refactor this to be more managable / elegant

const TechTags = ({ tags }) => {
	return (
		<div className="tech-container">
			<h3 className="label-tech-used">Technologies</h3>
			<ul>
				{tags.map((item, key) => {
					for (let [thiskey, value] of Object.entries({
						item,
					})) {
						// console.log(`${thiskey}: ${value}`)
						if (value.toLowerCase() === 'php') {
							return (
								<li key={key} className={'tech-' + item.toLowerCase()}>
									<FaPhp />
									<span>{item}</span>
								</li>
							)
						} else if (value.toLowerCase() === 'javascript') {
							return (
								<li key={key} className={'tech-' + item.toLowerCase()}>
									<FaJs />
									<span>{item}</span>
								</li>
							)
						} else if (value.toLowerCase() === 'less') {
							return (
								<li key={key} className={'tech-' + item.toLowerCase()}>
									<FaLess />
									<span>{item}</span>
								</li>
							)
						} else if (value.toLowerCase() === 'wordpress') {
							return (
								<li key={key} className={'tech-' + item.toLowerCase()}>
									<FaWordpress />
									<span>{item}</span>
								</li>
							)
						} else if (value.toLowerCase() === 'knockout') {
							return (
								<li key={key} className={'tech-' + item.toLowerCase()}>
									<i className="icon-ko"></i>
									<span>{item}</span>
								</li>
							)
						} else if (value.toLowerCase() === 'bootstrap') {
							return (
								<li key={key} className={'tech-' + item.toLowerCase()}>
									<FaBootstrap />
									<span>{item}</span>
								</li>
							)
						} else if (value.toLowerCase() === 'scss') {
							return (
								<li key={key} className={'tech-' + item.toLowerCase()}>
									<FaSass />
									<span>{item}</span>
								</li>
							)
						} else {
							return (
								<li key={key} className={'tech-' + item.toLowerCase()}>
									<FaGrin />
									<span>{item}</span>
								</li>
							)
						}
					}
				})}
			</ul>
		</div>
	)
}

export default TechTags
